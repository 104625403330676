html {
  font-family: "Montserrat", sans-serif;
}
body {
  margin: 0;
  background-color: #ffffff;
  color: #534d4d;
  font-family: "Montserrat", sans-serif;
  scroll-behavior: smooth;
}

html {
  scroll-behavior: smooth;
}

.headerSection {
  background-position: 97% 100%;
  background-repeat: no-repeat;
  height: 15em;
  position: inherit;
  background-size: cover;
  .headerSectionF {
    display: flex;
    height: 7em;
    align-items: center;
    .headerSectionFF {
      display: flex;
      height: 7em;
      align-items: center;
    }
    .headerSectionS {
      display: flex;
      height: 10em;
      align-items: center;
      justify-content: space-evenly;
      ul {
        margin: 0;
        padding: 0;
        display: inline-flex;
        list-style: none;
        li {
          padding: 0.5em;
          font-size: 1.1em;
          color: white;
          font-weight: 600;
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}

.btn-theme {
  padding: 0.7em 2.5em 0.7em 2.5em;
  box-shadow: 0px 10px 38px -9px rgba(0, 0, 0, 0.63);
  border: 0;
  background-color: #ff8923;
  border-radius: 1em;
  color: white;
  font-size: 1em;
  font-weight: 600;
}

.headerDownsct {
  height: 30em;
  .headerDownsctFc {
    height: 16em;
    .hdUn {
      font-size: 2.5rem;
      font-weight: 600;
    }
    .hdunl {
      font-size: 1em;
      line-height: 1.5;
      font-weight: 500;
    }
  }
}

.ourServices {
  margin: 1em 0 1em 0;
  .ourServicesA {
    text-align: center;
    color: blue;
    margin: 0.5em 0 0.5em 0;
    font-weight: 500;
  }
  .ourServicesB {
    text-align: center;
    font-size: 1.2em;
    margin: 0.5em 0 0.5em 0;
    font-weight: 600;
  }
  .ourServicesC {
    text-align: center;
    font-size: 1em;
    font-weight: lighter;
    line-height: 1.4;
    margin: 0.5em 0 0.5em 0;
  }
}

.showWr1 {
  background: linear-gradient(180deg, rgba(250, 185, 118, 1) 0%, rgba(223, 168, 113, 1) 100%);
}
.showWr2 {
  background: linear-gradient(180deg, rgba(64, 232, 226, 1) 0%, rgba(34, 147, 183, 1) 100%);
}
.showWr3 {
  background: linear-gradient(180deg, rgba(104, 81, 158, 1) 0%, rgba(70, 56, 101, 1) 100%);
}
.showWr4 {
  background: linear-gradient(180deg, rgba(66, 133, 209, 1) 0%, rgba(10, 98, 197, 1) 100%);
}
.showWr5 {
  background: linear-gradient(180deg, rgba(163, 160, 245, 1) 0%, rgba(90, 83, 215, 1) 100%);
}
.showWr6 {
  background: linear-gradient(180deg, rgba(252, 126, 134, 1) 0%, rgba(157, 68, 68, 1) 100%);
}
.showWr7 {
  background: linear-gradient(180deg, rgba(164, 237, 199, 1) 0%, rgba(94, 221, 155, 1) 100%);
}
.showWr8 {
  background: linear-gradient(180deg, rgba(238, 174, 202, 1) 0%, rgba(235, 139, 181, 1) 100%);
}
.showWr9 {
  background: linear-gradient(180deg, rgba(119, 137, 228, 1) 0%, rgba(86, 110, 230, 1) 100%);
}

.showWr {
  .showWrWr {
    box-shadow: 0px 10px 38px -9px rgba(0, 0, 0, 0.63);
    height: 20em;
    .showWrFc {
      height: 8em;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .showWrSc {
      text-align: center;
      color: white;
      font-weight: lighter;
      margin: 0.5em 0 0.5em 0;
      font-size: 0.9em;
    }
    .showWrTc {
      margin: 0.5em 0 0.5em 0;
      color: white;
      text-align: center;
      padding: 1em;
      font-size: 0.85em;
      font-weight: bold;
      line-height: 1.5;
    }
    .showWrFFc {
      margin: 0.5em 0 0.5em 0;
      display: flex;
      justify-content: center;
      display: none;
      button {
        border: 0;
        border-radius: 1em;
        padding: 0.5em 1em 0.5em 1em;
        color: blue;
        font-size: 0.85em;
      }
    }
  }
}

.knowAb {
  .knowAbFc {
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .knowAbSc {
    height: 30em;
    display: flex;
    align-items: center;
    .knowAbScWr {
      .knowAbScWrFc {
        font-size: 1em;
        font-weight: bold;
      }
      .knowAbScWrSc {
        font-size: 1em;
      }
      .knowAbScWrTc {
        height: 4em;
        display: flex;
        align-items: center;
        span {
          font-size: 0.9em;
        }
      }
    }
  }
}

.port {
  .ousc {
    text-align: center;
    font-weight: 600;
    font-size: 1.5em;
  }
  .ouscts {
    text-align: center;
    margin: 0.5em 0 0.5em 0;
    font-size: 1em;
    word-spacing: 1;
  }

  .portWr {
    box-shadow: 0px 10px 38px -9px rgba(0, 0, 0, 0.63);
    border-radius: 1.5em;
    padding: 1.5em;
    height: 17em;
    display: flex;
    justify-content: center;
    align-items: center;
    .portWrData {
      width: 100%;
      border-radius: 1em;
      height: 6em;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 700;
      span {
        font-size: 2em;
        font-weight: bolder;
      }
    }
    .portWrtxt {
      width: 100%;
      text-align: center;
      margin: 0.5em 0 0.5em 0;
      font-weight: 600;
      font-size: 1em;
    }
  }
  .portWrXX {
    box-shadow: 0px 10px 38px -9px rgba(0, 0, 0, 0.63);
    border-radius: 1.5em;
    padding: 1.5em;
    height: 25em;
    display: flex;
    justify-content: center;
    align-items: center;
    .portWrData {
      width: 100%;
      border-radius: 1em;
      height: 6em;
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      font-weight: 700;
      span {
        font-size: 2em;
        font-weight: bolder;
      }
    }
    .portWrtxtW {
      font-size: 1.1em;
      text-align: center;
    }
    .portWrtxt {
      width: 100%;
      text-align: center;
      margin: 0.5em 0 0.5em 0;
      font-weight: 600;
      font-size: 0.9em;
    }
  }
}

.portWrData1 {
  background: linear-gradient(126deg, rgba(173, 146, 253, 1) 0%, rgba(106, 84, 243, 1) 100%);
}
.portWrData2 {
  background: linear-gradient(126deg, rgba(255, 147, 165, 1) 0%, rgba(255, 98, 163, 1) 100%);
}
.portWrData3 {
  background: linear-gradient(126deg, rgba(251, 200, 162, 1) 0%, rgba(255, 137, 75, 1) 100%);
}
.portWrData4 {
  background: linear-gradient(180deg, #7789e4 0%, #566ee6 100%);
}
.portWrData5 {
  background: linear-gradient(180deg, #eeaeca 0%, #eb8bb5 100%);
}
.portWrData6 {
  background: linear-gradient(180deg, #a4edc7 0%, #5edd9b 100%);
}

.footPart {
  height: 40em;
  background-size: 100% 100%;
  .footParttxt {
    font-size: 2em;
    font-weight: bolder;
    text-align: center;
    color: white;
  }
  .footParttxx {
    font-size: 0.9em;
    text-align: center;
    color: white;
  }
  .footParttxx {
    text-align: center;
    color: white;
  }
  .fotre {
    text-align: center;
    margin-top: 27em;
    color: white;
    font-size: 1em;
    font-weight: 0.8em;
  }
  .revName {
    font-size: 1.5em;
    color: blue;
    font-weight: 700;
    text-align: center;
  }
  .revNameK {
    color: white;
    text-align: center;
    margin: 0.5em 0 0.5em 0;
  }
}

.keyFeature {
  height: 20em;
  img {
    width: 80%;
    height: 80%;
  }
}

.footer {
  .foot1 {
    height: 10em;
    .foot1Fc {
      font-size: 2em;
    }
    .foot1Sc {
      font-size: 0.9em;
      list-style: 0.9;
    }
    .foot1Tc {
      display: flex;
      div {
        margin: 0.5em;
      }
    }
  }
  .foot2 {
    .foot2Fc {
      font-size: 1.2em;
      color: gray;
      font-weight: 600;
    }
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      li {
        margin: 0.5em;
        color: #5e758c;
        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }
}

.fntMk {
  font-size: 0.8em;
}

.workFlow {
  .workFlowFc {
    height: 30em;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      height: 80%;
      width: 80%;
    }
  }
  .workFlowSc {
    display: flex;
    height: 30em;
    align-items: center;
    .workFlowSctxt {
      font-size: 1.2;
      font-weight: bolder;
      color: gray;
    }
  }
}

.ourstructure {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .ourstructuretlt {
    text-align: center;
    padding: 2em 0 1.5em 0;
    font-size: 1.4em;
    font-weight: 600;
    color: white;
  }
  .ourstructuretxt {
    text-align: center;
    font-size: 1em;
    font-weight: 500;
    color: white;
  }
  .orsWr {
    height: 25em;
    background-color: rgba(5, 5, 5, 0.322);
    .orsWrFc {
      height: 7em;
      width: 100%;
      padding: 2em 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 5em;
        height: 5em;
        border-radius: 50%;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 2px solid white;
      }
    }
    .orsWrSc {
      text-align: center;
      font-size: 1em;
      padding: 1.5em 0 1.5em 0;
      font-weight: 500;
      color: white;
    }
    .orsWrTc {
      font-size: 1em;
      line-height: 1.5;
      color: white;
      text-align: center;
    }
  }
}

.themeColor {
  color: #ffffff;
}

.themetxtColor {
  color: #fd8217;
}

.smtxt {
  font-size: 0.85em !important;
  padding: 0 0.2em 0 0.2em;
}

.footbg {
  background-position: center;
  background-size: 76% 100%;
}
